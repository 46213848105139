<template>
  <div class="partyBuilding">
    <div class="partyBuilding-t">
      <div class="banner" @click="toBanner">
        <img src="./img/banner.png" alt="图片加载中..." />
      </div>
      <div class="iconList">
        <div class="item te" @click="toPartyMember">
          <div class="item-t"><img src="./img/img1.png" alt="" /></div>
          <div class="item-b">亭下引</div>
        </div>
        <div class="item" @click="toPartyAffairs">
          <div class="item-t"><img src="./img/img2.png" alt="" /></div>
          <div class="item-b">亭下领</div>
        </div>
        <div class="item" @click="toRedHousekeeper">
          <div class="item-t"><img src="./img/img3.png" alt="" /></div>
          <div class="item-b">亭下智</div>
        </div>
        <div class="item" @click="toSpeak">
          <div class="item-t"><img src="./img/img4.png" alt="" /></div>
          <div class="item-b">亭下荟</div>
        </div>
      </div>
    </div>
    <div class="partyBuilding-title">居民议事厅</div>
    <div class="btn">
      <div class="btn-l" @click="toCommentRepresentative">议事代表</div>
      <div class="btn-r" @click="toCommentRule">议事规则</div>
    </div>
    <div class="partyBuilding-main">
      <div
        class="list-content"
        v-for="(item, index) in listData"
        :key="index"
        @click="toRouteTitle(item)"
      >
        <div class="list-sidebar">
          <img :src="item.topicPicture" />
        </div>
        <div class="list-wrapper">
          <div class="left-title">
            {{ item.topicTitle || "" }}
          </div>
          <div class="left-content" v-if="item.informationCopywriting != ''">
            <span>{{ item.informationCopywriting }}</span>
          </div>
          <div class="iconTag left-content" v-if="item.nowStatus">
            <span>{{ item.nowStatus }}</span>
          </div>
          <div class="left-wrap">
            <div class="iconText">
              <div class="icon">
                <img src="./img/icon-read.png" />
              </div>
              <div class="text">
                <span>{{ item.readCount }}</span>
              </div>
            </div>
            <div class="iconText">
              <div class="icon">
                <img src="./img/icon-msg.png" />
              </div>
              <div class="text">
                <span>{{ item.commentCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicListURL } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "partyBuilding",
  data() {
    return {
      fromFlag: "",
      form: {
        donatePic: "",
        donateContent: "",
      },
      pointsData: {
        totlePointCount: 0,
      },
      curPage: 1,
      pageSize: 5,
      listData: [],
      bannerList: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.fromFlag = this.$route.query.fromFlag;
  },
  mounted() {
    // this.getBannerList();
    gloabalCount("", 605, 1);
    this.getPartyList();
  },
  methods: {
    toCommentRepresentative() {
      this.$router.push({
        name: "commentRepresentative",
      });
    },
    toCommentRule() {
      this.$router.push({
        name: "commentRule",
      });
    },
    toClassScheduleCard() {
      // this.$router.push({
      //   name: "grassRootsPartySchool",
      //   query: {
      //     fromFlag: this.fromFlag,
      //   },
      // });
      location.href = "https://test.zhuneng.cn/special/dkkjl/";
    },
    toBanner() {
      this.$router.push({
        name: "partyNotice",
      });
    },
    toRedHousekeeper() {
      this.$router.push({
        name: "tingXiaZ",
      });
    },
    toSpeak() {
      this.$router.push({
        name: "tingXiaH",
      });
    },
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "tingTreatDetail",
        query: {
          id: item.id,
        },
      });
    },
    toPartyAffairs() {
      this.$router.push({
        name: "association",
      });
    },
    toPartyMember() {
      this.$router.push({
        name: "partyBuilding",
      });
    },
    async getPartyList() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicType: "63", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: this.curPage++,
          pageSize: this.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.partyBuilding {
  min-height: 100vh;
}
.partyBuilding {
  .partyBuilding-t {
    .banner {
      height: 308px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .iconList {
      display: flex;
      padding: 40px 20px 28px;
      align-items: center;
      justify-content: space-around;
      .item-t {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        margin: 18px auto;
        font-weight: 700;
        font-size: 28px;
        color: #1a1c34;
        line-height: 40px;
        text-align: center;
      }
    }
  }
  .classListBanner {
    box-sizing: border-box;
    width: 100%;
    height: 176px;
    padding: 0 30px;
    img {
      width: 690px;
      height: 100%;
    }
  }
  .partyBuilding-title {
    padding: 0px 0px 2px 28px;
    font-size: 36px;
    font-weight: 600;
    color: #1a1c34;
    line-height: 50px;
  }
  .btn {
    display: flex;
    align-items: center;
    padding: 20px 32px;
    .btn-l,
    .btn-r {
      width: 160px;
      margin-right: 20px;
      height: 52px;
      background: #fb8133;
      border-radius: 26px;
      font-weight: 400;
      font-size: 26px;
      color: #ffffff;
      line-height: 52px;
      text-align: center;
    }
  }
  .partyBuilding-main {
    padding: 20px 0;
    .list-content {
      border-radius: 16px;
      background: #ffffff;
      display: flex;
      overflow: hidden;
      margin: 0 32px 32px 32px;
      height: 256px;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      .list-sidebar {
        width: 256px;
        min-width: 256px;
        height: 100%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .list-wrapper {
        flex: 1;
        max-width: calc(100% - 256px);
        padding: 36px 30px 24px 28px;
        box-sizing: border-box;

        .left-title {
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 12px;
        }

        .left-content {
          margin-bottom: 66px;

          > span {
            padding: 4px 10px;
            box-sizing: border-box;
            background: #fff3eb;
            border-radius: 4px;
            border: 2px solid #ffc7a4;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fe6f16;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .left-wrap {
          display: flex;
          // justify-content: flex-end;
          align-items: center;

          .iconTag {
            flex: 1;
            overflow: hidden;
            margin-right: 8px;
            display: flex;
            align-items: center;

            > span {
              // display: flex;
              // justify-content: center;
              // align-items: center;
              // line-height: 33px;
              // line-height: 1;
              height: 32px;
              display: flex;
              // justify-content: center;
              align-items: center;
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              border-radius: 4px;
              font-size: 24px;
              background: #fff3eb;
              border-radius: 8px;
              border: 2px solid #ffc7a4;
              padding: 0px 8px;
              color: #fe6f16;
            }
          }

          .iconText {
            display: flex;
            color: #a8a8a8;
            align-items: center;

            &:not(:last-child) {
              margin-right: 32px;
            }

            .icon {
              display: flex;
              align-items: center;

              &.icon-up {
                > img {
                  margin-top: 4px;
                }
              }

              > img {
                width: 36px;
                height: 36px;
                // width: 100%;
                // height: 100%;
              }
            }

            .text {
              margin-left: 8px;
              display: flex;
              align-items: center;
              height: 36px;
              line-height: 36px;

              span {
                &.hasPraised {
                  color: #ff853a;
                }

                font-size: 24px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
